import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./NavBar.module.css";
import {
  unAuthMenuItemsData,
  authMenuItemsData,
  menuIcons,
} from "./navbarData";
import { useAuth } from "../../../contexts/AuthContext";

export default function NavBar() {
  const [menuState, setMenuState] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const { currentUser } = useAuth();
  const userAuthentication = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setAuthenticated(currentUser && localStorage.getItem("username"));
  }, [currentUser]);

  const handleSignOut = () => {
    userAuthentication.logOut();
    setAuthenticated(false);
    localStorage.clear();
    navigate("/home");
  };

  const genLinks = () => {
    const itemsInMenu = authenticated ? authMenuItemsData : unAuthMenuItemsData;
    return itemsInMenu.map((info, i) => {
      return (
        <button
          key={`l${i}`}
          className={classes.navButton}
          style={
            info.name === "Sign out" ? { background: "var(--accent)" } : null
          }
          onClick={() => {
            if (info.name === "Sign out") {
              handleSignOut();
            }
            navigate(info.route);
          }}
        >
          <img src={info.iconB} alt={info.name} className={classes.iconB} />
          <img src={info.iconW} alt={info.name} className={classes.iconW} />
          {<span className={classes.label}>{info.name}</span>}
        </button>
      );
    });
  };

  function HandleMenu() {
    setMenuState((prev) => !prev);
  }

  const navClass = menuState ? classes.menuBoxOn : classes.menuBox;
  return (
    <Fragment>
      {menuState ? (
        <div className={navClass}>
          <img
            src={menuIcons.close.route}
            alt="menu"
            className={classes.menuIconClose}
            onClick={HandleMenu}
          />

          {currentUser ? (
            <div className={classes.userMessage}>
              <p>
                Welcom <span className={classes.user}>{currentUser.email}</span>
              </p>
            </div>
          ) : null}
          {genLinks()}
        </div>
      ) : (
        <div className={navClass}>
          <img
            src={menuIcons.open.route}
            alt="menu"
            className={classes.menuIcon}
            onClick={HandleMenu}
          />
        </div>
      )}
    </Fragment>
  );
}
