import ml from "../../images/landing/ml.png";
import explainable from "../../images/landing/explainable.png";
import social from "../../images/landing/social.png";
import React from "react";

export const researchInterests = [
  {
    title: "Explainable AI",
    description:
      "My research interest lies in investigating the impact of interpretability and explainability of deep learning models on end user's trust, belief, and attitude. I am dedicated to unraveling the intricate relationships between human understanding and AI decision-making processes, striving to develop more transparent and user-centric AI systems that foster trust and facilitate effective collaboration between humans and machines.",
    image: explainable,
  },
  {
    title: "Social Computing",
    description:
      "I am interested in exploring the impact of data visualization techniques and user interaction with A.I. on end user's decision-making through belief and attitude change. Utilizing controlled experiments and crowd-sourced survey studies, I aim to better understand the underlying psychological and cognitive processes that guide users' engagement with visualizations, ultimately contributing to the development of more effective and intuitive interfaces for data-driven decision-making.",
    image: social,
  },
  {
    title: "Machine Learning",
    description:
      "Within the domain of machine learning, my research interest focuses on the design, development, and application of high-performance machine learning models, specifically in the field of Natural Language Processing (NLP). I am committed to advancing the state-of-the-art in NLP by creating novel algorithms and techniques that enhance language understanding, enabling more effective communication and collaboration between humans and artificial intelligence systems.",
    image: ml,
  },
];

export const aboutMe = {
  description:
    "I am a Ph.D. student at the Ribarsky Center for Visual Analytics, Department of Computer Science, College of Computing and Informatics, University of North Carolina at Charlotte. My research interests includes data visualization, explainable AI, and social computation. The rise in the application of artificial intelligence, especially in the fields requiring critical decision-making, raised questions regarding the autonomy and reliability of the predictions made by these models. My research focus is a twofold: How data visualization techniques can increase the efficiency and efficacy of AI-human decision-making? How do we convey the model results to lay users in such a way that it is persuasive?",
};

export const news = [
  {
    date: "03/23/2024",
    tag: "Research Symposium",
    body: (
      <p>
        My presentation, 'Can Elicitation and Contrasting Narratives Help with
        Recall, Engagement, and Attitude Change?' won the 2nd best oral
        presentation at{" "}
        <a href="https://gpsg.charlotte.edu/graduate-research-symposium/graduate-research-symposium-2024">
          the annual Graduate Research Symposium (GRS) at UNC Charlotte.
        </a>
      </p>
    ),
  },
  {
    date: "01/19/2024",
    tag: "New publication",
    body: (
      <p>
        Our most recent research paper, titled{" "}
        <a href="https://ieeexplore.ieee.org/abstract/document/10409610">
          The Impact of Elicitation and Contrasting Narratives on Engagement,
          Recall and Attitude Change with News Articles Containing Data
          Visualization
        </a>
        , published at IEEE Transactions on Visualization and Computer Graphics
        (TVCG) is now available for early access.
      </p>
    ),
  },
  {
    date: "02/10/2024",
    tag: "New publication",
    body: (
      <p>
        Our research paper, titled{" "}
        <a href="https://ascelibrary.org/doi/abs/10.1061/9780784485248.021">
          BIM as a Mitigation Tool for COVID-19 Management on a University
          Campus
        </a>
        , has been published in Computing in Civil Engineering 2023.
      </p>
    ),
  },
];
